import React, {useState, useEffect} from 'react'

const CountUpAnimation = ({ end, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    // The total number of ticks to reach the end
    const totalTicks = duration / 1000 * 60;
    // How much to increment the number by on each tick
    const increment = end / totalTicks;

    const interval = setInterval(() => {
      start += increment;
      setCount(start);
      if (start >= end) {
        clearInterval(interval);
        setCount(end);
      }
    }, 1000 / 60); // 60 fps

    return () => clearInterval(interval);
  }, [end, duration]);

  return <span>{Math.floor(count)}</span>;
};

const OurWork = () => {
  return (
    <div className="page-section counter-section">
      <div className="container">
        <div className="row align-items-center text-center">
          <div className="col-lg-4">
            <p>Total Projects</p>
            <h2><CountUpAnimation end={250} duration={10000} />+</h2>
          </div>
          <div className="col-lg-4">
            <p>Completed Projects</p>
            <h2><CountUpAnimation end={100} duration={10000} />+</h2>
          </div>
          <div className="col-lg-4">
            <p>Happy Customers</p>
            <h2><CountUpAnimation end={63} duration={10000} />+</h2>
          </div>
        </div>
      </div> 
    </div>
  )
}

export default OurWork