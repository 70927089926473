import React from "react";
import { Link, useLocation } from "react-router-dom";
import { NAV } from "../constants";
import { Banner } from "../assets/img";
const Navbar = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const pageName = location.pathname.substr(1);
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light navbar-float">
        <div className="container">
          <Link to='/' className="navbar-brand">
          EB<span className="text-primary">Tech.</span>
          </Link>

          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="navbar-collapse collapse" id="navbarContent">
            <ul className="navbar-nav ml-lg-4 pt-3 pt-lg-0">
              {NAV.map((item) => (
                <li className={`nav-item ${location.pathname === item.link ? 'active' : ''}`} key={item.link}>
                  <Link to={item.link} className="nav-link">
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="ml-auto">
              <a href="/contact-us" className="btn btn-outline rounded-pill">
                Get a Quote
              </a>
            </div>
          </div>
        </div>
      </nav>
      {isHome ? (
        <div className="page-banner home-banner">
          <div className="container h-100">
            <div className="row align-items-center h-100">
              <div className="col-lg-6 py-3 wow fadeInUp">
                <h1 className="mb-4">
                  Great Companies are built on great Products
                </h1>
                <p className="text-lg mb-5">
                  Ignite the most powerful growth engine you have ever built for
                  your company
                </p>

                <a href="#" className="btn btn-outline border text-secondary">
                  More Info
                </a>
                <a href="#" className="btn btn-primary btn-split ml-2">
                  Watch Video{" "}
                  <div className="fab">
                    <span className="mai-play"></span>
                  </div>
                </a>
              </div>
              <div className="col-lg-6 py-3 wow zoomIn">
                <div className="img-place">
                  <img src={Banner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class="container" style={{marginTop:"130px"}}>
          <div class="page-banner">
            <div class="row justify-content-center align-items-center h-100">
              <div class="col-md-6">
                <nav aria-label="Breadcrumb">
                  <ul class="breadcrumb justify-content-center py-0 bg-transparent">
                  <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li class="breadcrumb-item active text-capitalize">
                      {pageName}
                    </li>
                  </ul>
                </nav>
                <h1 class="text-center text-capitalize">{pageName} Page</h1>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
