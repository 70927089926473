export const NAV=[
    {
        title:'Home',
        link:'/'
    },
    {
        title:'About',
        link:'/about'
    },
    {
        title:'Services',
        link:'/services'
    },
    {
        title:'Contact Us',
        link:'/contact-us'
    },
]