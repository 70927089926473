import React from 'react'
import { Pricing } from '../../assets/img'

const GetQuote = () => {
  return (
    <div className="page-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 py-3 wow fadeInLeft">
            <h2 className="title-section">We're <span className="marked">ready to</span> Serve you with best</h2>
            <div className="divider"></div>
            <p className="mb-5">We provide marketing services to startups & small business to looking for partner for their digital media, design & dev lead generation & communication.</p>
            <button href="#" className="btn btn-primary">Get Quote</button>
          </div>
          <div className="col-lg-6 py-3 wow zoomIn">
            <div className="img-place text-center">
              <img src={Pricing} alt=""/>
            </div>
          </div>
        </div>
      </div> 
    </div>
  )
}

export default GetQuote