import React from 'react'
import ContactForm from '../Common/ContactForm'

const ContactUs = () => {
  return (
    <main>
      <ContactForm />
    </main>
  )
}

export default ContactUs