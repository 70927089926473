import React from 'react'
import GetQuote from '../Common/GetQuote'
import WhyUs from '../Common/WhyUs'

const Services = () => {
  return (
    <main>
    <GetQuote/>
    <WhyUs/>
  </main>
  )
}

export default Services