import React from 'react'
import { AirBnb, Google, MailChimp,PayPal,Stripe, } from '../../assets/img';

const WorkWith = () => {
  return (
    <div className="page-section client-section">
      <div className="container-fluid">
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 justify-content-center">
          <div className="item wow zoomIn">
            <img src={AirBnb} alt=""/>
          </div>
          <div className="item wow zoomIn">
            <img src={Google} alt=""/>
          </div>
          <div className="item wow zoomIn">
            <img src={Stripe} alt=""/>
          </div>
          <div className="item wow zoomIn">
            <img src={PayPal} alt=""/>
          </div>
          <div className="item wow zoomIn">
            <img src={MailChimp} alt=""/>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default WorkWith