import React from 'react'

const ContactForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(event.target.value)
    const form = event.target;
    const fullName = form.elements["fullName"].value;
    const email = form.elements["email"].value;
    const message = form.elements["message"].value;
    window.location = `mailto:husnainshabbir06@gmail.com?subject=Message from ${fullName}&body=${message}`;
  };

  return (
    <div className="page-section">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 py-3 wow fadeInUp">
          <h2 className="title-section">Get in Touch</h2>
          <div className="divider"></div>
          <p>
            We're here to help and answer any questions you might have.
            <br /> We look forward to hearing from you!
          </p>

          <ul className="contact-list">
            <li>
              <div className="icon">
                <span className="mai-map"></span>
              </div>
              <div className="content">TobaTek, Pakistan</div>
            </li>
            <li>
              <div className="icon">
                <span className="mai-mail"></span>
              </div>
              <div className="content">
                <a href="#">hr@ebtech.com.pk</a>
              </div>
            </li>
            <li>
              <div className="icon">
                <span className="mai-phone-portrait"></span>
              </div>
              <div className="content">
                <a href="#">+92 344 7856300</a>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-lg-6 py-3 wow fadeInUp">
          <div className="subhead">Contact Us</div>
          <h2 className="title-section">Drop Us a Line</h2>
          <div className="divider"></div>

          <form onSubmit={handleSubmit}>
            <div className="py-2">
              <input
                type="text"
                className="form-control"
                name="fullName"
                placeholder="Full name"
              />
            </div>
            <div className="py-2">
              <input
                type="text"
                className="form-control"
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="py-2">
              <textarea
                rows="6"
                className="form-control"
                name="message"
                placeholder="Enter message"
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn btn-primary rounded-pill mt-4"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ContactForm