import React from "react";
import WorkWith from "../Common/WorkWith";
import Founders from "../Common/Founders";
import OurWork from "../Common/OurWork";
import Detail from "../Common/Detail";

const About = () => {
  return (
    <main>
      <Detail />

      <OurWork />

      <Founders />

      <WorkWith />
    </main>
  );
};

export default About;
