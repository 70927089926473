import React from "react";
import { Pattern } from "../../assets/img";
import Founders from "../Common/Founders";
import WorkWith from "../Common/WorkWith";
import GetQuote from "../Common/GetQuote";
import WhyUs from "../Common/WhyUs";
import OurWork from "../Common/OurWork";
import Detail from "../Common/Detail";
import ContactForm from "../Common/ContactForm";
const Home = () => {
  return (
    <main>
      <div className="page-section features">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4 py-3 wow fadeInUp">
              <div className="d-flex flex-row">
                <div className="img-fluid mr-3">
                  <img src={Pattern} alt="" />
                </div>
                <div>
                  <h5>Provide financial advice by our advisor</h5>
                  <p>Copywrite, blogpublic realations content translation.</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 py-3 wow fadeInUp">
              <div className="d-flex flex-row">
                <div className="img-fluid mr-3">
                  <img src={Pattern} alt="" />
                </div>
                <div>
                  <h5>Complete solutions for global organisations</h5>
                  <p>Copywrite, blogpublic realations content translation.</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 py-3 wow fadeInUp">
              <div className="d-flex flex-row">
                <div className="img-fluid mr-3">
                  <img src={Pattern} alt="" />
                </div>
                <div>
                  <h5>Provide financial advice by our advisor</h5>
                  <p>Copywrite, blogpublic realations content translation.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Detail />
      <OurWork />
      <GetQuote />
      <WhyUs />
      <Founders />

      <ContactForm />

      <WorkWith />
    </main>
  );
};

export default Home;
