import React from 'react';
import {Link} from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-lg-3 py-3">
            <h3>EB<span className="text-primary">Tech.</span></h3>
            <p>Business Solutions.</p>
            <p><a href="mailto:hr@ebtech.com.pk">hr@ebtech.com.pk</a></p>
            <p><a href="tel:+00112233445566">+92 344 7856300</a></p>
          </div>
          <div className="col-lg-3 py-3">
            <h5>Quick Links</h5>
            <ul className="footer-menu">
              <li><a href="#">How it works</a></li>
              <li><a href="#">Security</a></li>
              <li><a href="#">Resources</a></li>
              <li><a href="#">Report a Bug</a></li>
            </ul>
          </div>
          <div className="col-lg-3 py-3">
            <h5>About Us</h5>
            <ul className="footer-menu">
              <li><Link to='/about'>About Us</Link></li>
              <li><Link to='/about'>Our Teams</Link></li>
              <li><Link to='/about'>Testimonials</Link></li>
            </ul>
          </div>
          <div className="col-lg-3 py-3">
            <h5>Subscribe</h5>
            <form action="#">
              <input type="email" className="form-control" placeholder="Enter your email.." />
            </form>
            <div className="sosmed-button mt-4">
              <a href="#"><span className="mai-logo-facebook-f"></span></a>
              <a href="#"><span className="mai-logo-twitter"></span></a>
              <a href="#"><span className="mai-logo-google"></span></a>
              <a href="#"><span className="mai-logo-linkedin"></span></a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 py-2">
            <p id="copyright">&copy; 2022 <a href="#">EBTech</a>. All rights reserved</p>
          </div>
          <div className="col-sm-6 py-2 text-right">
            <div className="d-inline-block px-3">
              <a href="#">Privacy</a>
            </div>
            <div className="d-inline-block px-3">
              <Link to='/contact-us'>Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
