import React from 'react'
import { Husnain, PersonFake } from '../../assets/img';

const Founders = () => {
  return (
    <div className="page-section bg-light">
      <div className="container">
        
        <div className="owl-carousel wow fadeInUp" id="testimonials">
          <div className="item">
            <div className="row align-items-center">
              <div className="col-md-6 py-3">
                <div className="testi-image">
                  <img src={PersonFake} alt=""/>
                </div>
              </div>
              <div className="col-md-6 py-3">
                <div className="testi-content">
                  <p>Muhammad Bilal is a visionary leader and the driving force behind EBTech's success. With a profound commitment to innovation and excellence, He has steered the company through significant growth and transformation, embodying the core values and mission of the organization. Possessing a dynamic blend of strategic thinking and operational expertise, Bilal has a proven track record in making bold decisions that propel the company forward while maintaining a strong commitment to sustainability and ethical leadership.</p>
                  <div className="entry-footer">
                    <strong>Muhammad Bilal</strong> &mdash; <span className="text-grey">CEO EBTech.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div className="item">
            <div className="row align-items-center">
              <div className="col-md-6 py-3">
                <div className="testi-image">
                  <img src={PersonFake} alt=""/>
                </div>
              </div>
              <div className="col-md-6 py-3">
                <div className="testi-content">
                  <p>Muhammad Hasnain Shabbir is a seasoned Senior Software Engineer at EBTech, where he plays a pivotal role in driving technological innovation and excellence. With a passion for coding and a deep understanding of cutting-edge technologies, Hussnain excels in designing, developing, and optimizing software solutions that meet and exceed the dynamic needs of our clients. His expertise spans across multiple programming languages and development frameworks, enabling EBTech to lead in delivering robust, scalable, and innovative software products.</p>
                  <div className="entry-footer">
                    <strong>Muhammad Hasnain Shabbir</strong> &mdash; <span className="text-grey">Senior Software Engineer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div> 
    </div>
  )
}

export default Founders