import {createBrowserRouter,RouterProvider} from "react-router-dom";
import Layout from "../layout";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import ServicesPage from "../pages/ServicesPage";
import ContactPage from "../pages/ContactPage";

// ----------------------------------------------------------------------

export default function Router() {

  const routes = createBrowserRouter([
    {
      path: "/",
      element: <Layout/>,
      children: [
        {
          path: "/",
          element: (
              <HomePage/>
          ),
        },
        {
          path: "*",
          element: (
              <HomePage/>
          ),
        },
        {
          path: "/about",
          element: (
              <AboutPage />
          ),
        },
        {
          path: "/services",
          element: (
              <ServicesPage />
          ),
        },
        {
          path: "/contact-us",
          element: (
              <ContactPage />
          ),
        },
      ],
    },
  ]);

  return (
    <>
    <RouterProvider router={routes}/>
    </>
  )
}